import { GovtechDigitalAcademyCertificateOfCompletion_1SigneeV2Template } from './certificate-of-completion-1-signee-v2.template'

export const govtechDigitalAcademyCertificateOfCompletion_1SigneeV2Templates = [
    {
        id: 'govtechDigitalAcademyCertificateOfCompletion_1SigneeV2',
        label: 'Certificate',
        template:
            GovtechDigitalAcademyCertificateOfCompletion_1SigneeV2Template,
    },
]
