import { GovtechDigitalAcademyCertificateOfAchievement_1SigneeV2Template } from './certificate-of-achievement-1-signee-v2.template'

export const govtechDigitalAcademyCertificateOfAchievement_1SigneeV2Templates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfAchievement_1SigneeV2',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfAchievement_1SigneeV2Template,
        },
    ]
