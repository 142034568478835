import { GovtechDigitalAcademyCertificateOfCompletion_2SigneesV2Template } from './certificate-of-completion-2-signees-v2.template'

export const govtechDigitalAcademyCertificateOfCompletion_2SigneesV2Templates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfCompletion_2SigneesV2',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfCompletion_2SigneesV2Template,
        },
    ]
