import { GovtechDigitalAcademyCertificateOfParticipationV3_1SigneeTemplate } from './certificate-of-participation-v3-1-signee.template'

export const govtechDigitalAcademyCertificateOfParticipationV3_1SigneeTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfParticipationV3_1Signee',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfParticipationV3_1SigneeTemplate,
        },
    ]
