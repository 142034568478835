import { GovtechDigitalAcademyCertificateOfParticipationV3_2SigneesTemplate } from './certificate-of-participation-v3-2-signees.template'

export const govtechDigitalAcademyCertificateOfParticipationV3_2SigneesTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfParticipationV3_2Signees',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfParticipationV3_2SigneesTemplate,
        },
    ]
