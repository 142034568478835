import { GovtechDigitalAcademyCertificateOfAchievement_2SigneesV2Template } from './certificate-of-achievement-2-signees-v2.template'

export const govtechDigitalAcademyCertificateOfAchievement_2SigneesV2Templates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfAchievement_2SigneesV2',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfAchievement_2SigneesV2Template,
        },
    ]
